import dayjs from 'dayjs'
import {
  CustomerFindInvoiceFragment,
  InvoiceStatus
} from 'graph/generated/payments/graphql-types'
import { useRouter } from 'next/router'

import useActiveUserInfo from '@/hooks/useActiveUserInfo'

const convertAmount = (
  amount: string | string[] | undefined,
  pathname: string
) => {
  if (!amount) return 0

  const usingRouteAsParam = pathname.includes('custom-invoice/[amount]')
  if (usingRouteAsParam) {
    return Number(amount) / 100
  }
  // Remove $ symbol, commas, and whitespace before converting to float
  const cleanAmount = (amount as string).replace(/[$,\s]/g, '').trim()
  return parseFloat(cleanAmount)
}

const useCustomInvoiceData = (
  pathname: string
): CustomerFindInvoiceFragment => {
  const { customer } = useActiveUserInfo()
  const {
    query: { amount }
  } = useRouter()

  const amountNumber = convertAmount(amount, pathname)
  const today = dayjs().format('YYYY-MM-DD')

  return {
    __typename: 'Invoice',
    id: '',
    invoiceNumber: '',
    amount: amountNumber,
    outstandingAmount: amountNumber,
    status: InvoiceStatus.Upcoming,
    createdAt: today,
    dueDate: today,
    customer: {
      __typename: 'Customer',
      id: customer?.id,
      name: customer?.name
    },
    offer: null,
    lending: null,
    productName: '',
    recurringpayment: false,
    totalTaxAmount: 0,
    lineItems: [],
    platformName: '',
    totalDiscountAmount: 0
  }
}

export default useCustomInvoiceData
