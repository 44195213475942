import {
  useGetUserInfoQuery,
  CustomerUserInfoDataFragment,
  CustomerInfoFragment,
  UserType,
  CustomerUserPermissions
} from 'graph/generated/payments/graphql-types'
import Cookies from 'js-cookie'
import { AppCookie } from 'utils/cookies'

/**
 * Hook to extract active user account
 * information based on X-Customer-Id cookie
 */
const useActiveUserInfo = (): {
  loading: boolean
  user: CustomerUserInfoDataFragment
  customer: CustomerInfoFragment
  userType: UserType
} => {
  const { data, loading } = useGetUserInfoQuery()
  const customerId = Cookies.get(AppCookie.CustomerId)
  const isLoggedAsGuest = Cookies.get(AppCookie.LoggedAsGuest)

  if (isLoggedAsGuest === 'true') {
    return {
      user: {
        __typename: 'CustomerUserInfo',
        id: '00000000-0000-0000-0000-000000000000',
        role: 'guest',
        email: '',
        createdAt: '',
        permissions: [
          CustomerUserPermissions.BankingNew,
          CustomerUserPermissions.CardManage
        ]
      },
      customer: null,
      loading: false,
      userType: null
    }
  }

  const user = data?.GetUserInfo?.users?.find(
    user => user.customer?.id === customerId
  )

  return {
    user,
    customer: user?.customer,
    loading,
    userType: data?.GetUserInfo?.userType
  }
}

export default useActiveUserInfo
