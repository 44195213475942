/* eslint-disable turbo/no-undeclared-env-vars */
export const origin =
  typeof window !== 'undefined' &&
  window.location.origin &&
  window.location.origin !== 'undefined' &&
  window.location.origin !== 'null'
    ? window.location.origin
    : ''

export const paymentsDomainLocalFix = process.env.NEXT_PUBLIC_PORTAL_DOMAIN
  ? process.env.NEXT_PUBLIC_PORTAL_DOMAIN
  : origin.includes('://')
  ? origin.split('://')[1]
  : ''
