import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { ApolloError } from '@apollo/client/errors'
import { datadogRum } from '@datadog/browser-rum'
import {
  useFindInvoiceLazyQuery,
  useFindPartnerByPaymentsPortalUrlLazyQuery,
  CustomerPartnerFragment,
  CustomerFindInvoiceFragment,
  CustomerCreditCardFeeFragment
} from 'graph/generated/payments/graphql-types'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { createBrandColor } from 'utils/colors'
import { AppCookie } from 'utils/cookies'
import { PaymentMethod } from 'utils/payments'
import { ColorProps } from 'utils/types'
import { origin, paymentsDomainLocalFix } from 'utils/window'

import useCustomInvoiceData from './useCustomInvoiceData'

/**
 * Provider that supplies company information when a company url is provided.
 * Supplies invoice information when id is url param
 */

interface ICompanyAndInvoiceContext {
  company: CustomerPartnerFragment
  brandColor: ColorProps
  invoice: CustomerFindInvoiceFragment | null
  error: ApolloError
  loading: boolean
  paymentAmount: number
  setPaymentAmount: (amount: number) => void
  installmentTerm: number
  setInstallmentTerm: (term: number) => void
  payInInstallments: boolean
  setPayInInstallments: (payInInstallments: boolean) => void
  payAsGuest: boolean
  setPayAsGuest: (payAsGuest: boolean) => void
  cardFee: CustomerCreditCardFeeFragment | null
  setCardFee: (creditCardFee: CustomerCreditCardFeeFragment) => void
  isCustomInvoice: boolean
  methodSelected: PaymentMethod | null
  setMethodSelected: (method: PaymentMethod) => void
  isEditingPayAmount: boolean
  setIsEditingPayAmount: (value: boolean) => void
}

export const CompanyAndInvoiceContext =
  createContext<ICompanyAndInvoiceContext>({
    company: null,
    brandColor: null,
    invoice: null,
    error: null,
    loading: false,
    paymentAmount: 0,
    setPaymentAmount: () => {},
    installmentTerm: 1,
    setInstallmentTerm: () => {},
    payInInstallments: false,
    setPayInInstallments: () => {},
    payAsGuest: false,
    setPayAsGuest: () => {},
    cardFee: null,
    setCardFee: () => {},
    isCustomInvoice: false,
    methodSelected: null,
    setMethodSelected: () => {},
    isEditingPayAmount: false,
    setIsEditingPayAmount: () => {}
  })

const CompanyAndInvoiceProvider = ({ children }) => {
  const {
    query: { invoice },
    pathname
  } = useRouter()
  const isCustomInvoice = pathname.includes('/custom-invoice')

  const customInvoiceData = useCustomInvoiceData(pathname)

  const [installmentTerm, setInstallmentTerm] = useState<number>(1)
  const [paymentAmount, setPaymentAmount] = useState<number | null>(null)
  const [payInInstallments, setPayInInstallments] = useState<boolean>(false)
  const [payAsGuest, setPayAsGuest] = useState<boolean>(
    Cookies.get(AppCookie.LoggedAsGuest) === 'true'
  )
  const [cardFee, setCardFee] = useState<CustomerCreditCardFeeFragment>(null)
  const [methodSelected, setMethodSelected] = useState<PaymentMethod>()
  const [isEditingPayAmount, setIsEditingPayAmount] = useState<boolean>(false)

  const [fetchCompany, companyQuery] =
    useFindPartnerByPaymentsPortalUrlLazyQuery()
  const [fetchInvoice, invoiceQuery] = useFindInvoiceLazyQuery()

  const value = useMemo(() => {
    const company = companyQuery.data?.FindPartnerByPaymentsPortalUrl

    // set only on the first load
    const invoiceData = isCustomInvoice
      ? customInvoiceData
      : invoiceQuery.data?.FindInvoice

    if (!paymentAmount) {
      setPaymentAmount(invoiceData?.outstandingAmount ?? 0)
    }

    return {
      company,
      brandColor: createBrandColor(company?.paymentsPortalCustomization?.color),
      invoice: invoiceData,
      loading: companyQuery.loading,
      error: companyQuery.error || invoiceQuery.error,
      paymentAmount,
      setPaymentAmount,
      installmentTerm,
      setInstallmentTerm,
      payInInstallments,
      setPayInInstallments,
      payAsGuest,
      setPayAsGuest,
      cardFee,
      setCardFee,
      isCustomInvoice,
      methodSelected,
      setMethodSelected,
      isEditingPayAmount,
      setIsEditingPayAmount
    }
  }, [
    companyQuery.data,
    invoiceQuery.data,
    companyQuery.loading,
    paymentAmount,
    installmentTerm,
    payInInstallments,
    payAsGuest,
    cardFee,
    isCustomInvoice,
    methodSelected,
    isEditingPayAmount
  ])

  useEffect(() => {
    if (pathname.includes('installments')) {
      setPayInInstallments(true)
    }
  }, [pathname])

  useEffect(() => {
    if (
      paymentsDomainLocalFix &&
      paymentsDomainLocalFix !== 'undefined' &&
      paymentsDomainLocalFix !== 'null'
    ) {
      datadogRum.addAction('calling FindPartnerByPaymentsPortalUrlQuery', {
        url: paymentsDomainLocalFix,
        origin: origin,
        location: window.location
      })
      fetchCompany({
        variables: { url: paymentsDomainLocalFix }
      })
    }
  }, [fetchCompany])

  useEffect(() => {
    if (invoice) {
      setPaymentAmount(null)
      setInstallmentTerm(1)
      if (!isCustomInvoice) {
        fetchInvoice({ variables: { id: invoice as string } })
      }
    }
  }, [invoice, fetchInvoice, isCustomInvoice])

  return (
    <CompanyAndInvoiceContext.Provider value={value}>
      {children}
    </CompanyAndInvoiceContext.Provider>
  )
}

export const useCompanyAndInvoiceContext = () =>
  useContext(CompanyAndInvoiceContext)

export default CompanyAndInvoiceProvider
